import React from "react";

const Cleaner = () => {
  return (
    <iframe
      src="/cleaner.html"
      title="HTML Content"
      style={{ width: "100%", height: "100vh", border: "none" }}
    ></iframe>
  );
};

export default Cleaner;