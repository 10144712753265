import { webhookUrl } from "../constants";
import { LinkSuggestionList } from "../LinkSuggestion";

// Function to get the selected text from the editor
const getSelectedText = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  if (selectionState.isCollapsed()) {
    // If no text is selected, return an empty string
    return "";
  }

  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();

  const blockMap = contentState.getBlockMap();
  let selectedText = "";

  let captureText = false;

  blockMap.forEach((block) => {
    const key = block.getKey();
    const blockText = block.getText();

    if (key === startKey && key === endKey) {
      // If start and end are in the same block
      selectedText = blockText.slice(startOffset, endOffset);
    } else if (key === startKey) {
      // Start capturing from startOffset
      selectedText += blockText.slice(startOffset) + "\n";
      captureText = true;
    } else if (key === endKey) {
      // Stop capturing after endOffset
      selectedText += blockText.slice(0, endOffset);
      captureText = false;
    } else if (captureText) {
      // Capture full block text between start and end block
      selectedText += blockText + "\n";
    }
  });

  return selectedText;
};

// Function to count words in a given text
const countWords = (text) => {
  const words = text.trim().split(/\s+/);
  return words && words.length === 1 && words[0] === "" ? 0 : words.length;
};

// Function to get the word count of the selected text
export const getSelectedWordCount = (editorState) => {
  const selectedText = getSelectedText(editorState);
  return countWords(selectedText);
};

export const getNewSuggestionList = (defaultWebsiteUrl) => {
  const basePath = defaultWebsiteUrl.endsWith("/")
    ? defaultWebsiteUrl
    : `${defaultWebsiteUrl}/`;

  const newLinkSuggestionList = {};

  for (const [key, value] of Object.entries(LinkSuggestionList)) {
    newLinkSuggestionList[key] = basePath + value;
  }
  return newLinkSuggestionList;
};

// Function to compress image
export const compressImage = async (imageUrl, width, height, quality) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";

    img.onload = () => {
      // Calculate aspect ratio maintaining dimensions
      // let newWidth = width;
      // let newHeight = height;
      // const aspectRatio = img.width / img.height;

      // if (width / height > aspectRatio) {
      //   newWidth = height * aspectRatio;
      // } else {
      //   newHeight = width / aspectRatio;
      // }

      // canvas.width = newWidth;
      // canvas.height = newHeight;

      // const ctx = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0, newWidth, newHeight);

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Convert to blob with specified quality
      canvas.toBlob((blob) => resolve(blob), "image/jpeg", quality);
    };

    img.onerror = () => reject(new Error("Failed to load image"));
    img.src = imageUrl;
  });
};

export const combineProfileImages = async (imageUrls, imageSize) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const overlap = 15; // Amount of overlap between circles
    const totalWidth = imageUrls.length * (imageSize - overlap) + overlap;

    canvas.width = totalWidth;
    canvas.height = imageSize;

    const loadImages = imageUrls.map((url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    });

    Promise.all(loadImages)
      .then((images) => {
        images.forEach((img, index) => {
          // Create clipping path for circle
          ctx.save();
          ctx.beginPath();
          const x = index * (imageSize - overlap) + imageSize / 2;
          const y = imageSize / 2;
          ctx.arc(x, y, imageSize / 2, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.clip();

          // Draw image
          const aspectRatio = img.width / img.height;
          let drawWidth = imageSize;
          let drawHeight = imageSize;
          let offsetX = 0;
          let offsetY = 0;

          if (aspectRatio > 1) {
            drawWidth = imageSize * aspectRatio;
            offsetX = -(drawWidth - imageSize) / 2;
          } else {
            drawHeight = imageSize / aspectRatio;
            offsetY = -(drawHeight - imageSize) / 2;
          }

          ctx.drawImage(
            img,
            index * (imageSize - overlap) + offsetX,
            offsetY,
            drawWidth,
            drawHeight
          );

          ctx.restore();
        });

        canvas.toBlob((blob) => resolve(blob), "image/png", 1.0);
      })
      .catch(reject);
  });
};

export const updateLast6Images = (tenImagesPropts, last6ImagePropts) => {
  // Create a copy of tenImagesPropts to avoid modifying the original
  const availableImages = [...tenImagesPropts];

  // Shuffle array using Fisher-Yates algorithm
  for (let i = availableImages.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [availableImages[i], availableImages[j]] = [
      availableImages[j],
      availableImages[i],
    ];
  }

  // Take first 6 images from shuffled array
  const selectedImages = availableImages.slice(0, 6);

  // Map selected images to last6ImagePropts format
  return last6ImagePropts.map((item, index) => {
    return {
      ...item,
      image: selectedImages[index].image,
      prompt: selectedImages[index].prompt,
      imageIndex: selectedImages[index].imageIndex,
    };
  });
};

// Function to send message to Slack
export const sendSlackMessage = async (message) => {
  try {
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.text(); // Slack returns 'ok' for successful requests
  } catch (error) {
    console.error("Error sending message to Slack:", error);
  }
};
