import Cookies from "js-cookie";
import { decryptData, encryptData } from "./crypto";
import { variables } from "./variables";

const COOKIE_DOMAIN = window.location.hostname;

export const setCookie = (name, value, options = {}) => {
  const encryptedValue = encryptData(value);
  Cookies.set(name, encryptedValue, {
    ...options,
    secure: true, // Only transmitted over HTTPS
    sameSite: "strict", // Protect against CSRF
    domain: COOKIE_DOMAIN,
  });
};

export const getCookie = (name) => {
  const encryptedValue = Cookies.get(name);
  if (!encryptedValue) return null;
  return decryptData(encryptedValue);
};

export const removeCookie = (name) => {
  Cookies.remove(name, {
    domain: COOKIE_DOMAIN,
    path: "/",
  });
};

export const verifyCookie = () => {
  try {
    const encryptedValue = Cookies.get(variables.COOKIE_NAME);
    if (!encryptedValue) {
      return false;
    }

    const authData = decryptData(encryptedValue);
    if (!authData || !authData.token || !authData.expiry) {
      return false;
    }

    // Check expiration
    if (new Date().getTime() > authData.expiry) {
      Cookies.remove(variables.COOKIE_NAME);
      return false;
    }

    // Verify token structure (you can add more validation as needed)
    if (typeof authData.token !== "string" || authData.token.length < 10) {
      return false;
    }

    return true;
  } catch (error) {
    console.error("Cookie verification failed:", error);
    return false;
  }
};
