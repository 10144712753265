import React, { useState, useRef, useEffect } from "react";
import { Editor, EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import "./AutoPromptPicker.css";

const excludeStrings = ["top destinations in", "rent a bus to visit"];

const AutoPromptPicker = ({ onCopy }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();
  const editorWrapperRef = useRef();

  const resetEditor = () => {
    setEditorState(EditorState.createEmpty());
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetEditor();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanAndCopyText = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    const cleanedBlocks = rawContent.blocks.filter((block) => {
      // Check if it's a header-two and has content
      if (block.type === "header-two" && block.text.trim().length > 0) {
        const text = block.text.trim().toLowerCase();
        // Convert exclude strings to lowercase before comparison
        return !excludeStrings
          .map((str) => str.toLowerCase())
          .some(
            (excludeStr) =>
              text.includes(excludeStr) || text.startsWith(excludeStr)
          );
      }
      return false;
    });

    const cleanedRawContent = {
      ...rawContent,
      blocks: cleanedBlocks,
    };

    const newContentState = convertFromRaw(cleanedRawContent);
    const newEditorState = EditorState.createWithContent(newContentState);
    setEditorState(newEditorState);

    // Copy the cleaned text after state update
    setTimeout(() => {
      const cleanedContentState = newEditorState.getCurrentContent();
      const plainText = cleanedContentState.getPlainText();
      onCopy(plainText);
      handleCloseModal();
    }, 0);
  };

  const openModal = () => setIsModalOpen(true);

  return (
    <div>
      <button onClick={openModal} className="button">
        Paste In Editor
      </button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" ref={modalRef}>
            <div className="modal-header">
              <h2>Paste Content of Top Destination and Neighbourhood Here</h2>
              <button onClick={handleCloseModal} className="close-button">
                ×
              </button>
            </div>

            <div className="relative">
              <div className="editor-wrapper" ref={editorWrapperRef}>
                <Editor
                  editorState={editorState}
                  onChange={setEditorState}
                  placeholder="Start typing..."
                />
              </div>

              <div className="sticky-button-container">
                <button
                  onClick={cleanAndCopyText}
                  className="clean-button sticky top-0 z-10 w-full bg-white shadow-md"
                >
                  Filter Out Destination and Neighbourhood Names
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoPromptPicker;
