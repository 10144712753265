import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { variables } from "./variables";
import { getCookie, setCookie } from "./cookies";

const COOKIE_DOMAIN = window.location.hostname;

const removeCookie = (name) => {
  Cookies.remove(name, {
    domain: COOKIE_DOMAIN,
    path: "/",
  });
};

const decryptString = (encryptedStr) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedStr, variables.SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch {
    return null;
  }
};

export const validateCredentials = (username, password) => {
  // Store encrypted credentials for multiple users
  const encryptedUsers = [
    {
      username: variables.ENCRYPTED_USERNAME_1,
      password: variables.ENCRYPTED_PASSWORD_1,
    },
    {
      username: variables.ENCRYPTED_USERNAME_2,
      password: variables.ENCRYPTED_PASSWORD_2,
    },
    {
      username: variables.ENCRYPTED_USERNAME_3,
      password: variables.ENCRYPTED_PASSWORD_3,
    },
    {
      username: variables.ENCRYPTED_USERNAME_4,
      password: variables.ENCRYPTED_PASSWORD_4,
    },
    {
      username: variables.ENCRYPTED_USERNAME_5,
      password: variables.ENCRYPTED_PASSWORD_5,
    },
    {
      username: variables.ENCRYPTED_USERNAME_6,
      password: variables.ENCRYPTED_PASSWORD_6,
    },
  ];

  // Check credentials against all stored users
  return encryptedUsers.some((user) => {
    const decryptedStoredUser = decryptString(user.username);
    const decryptedStoredPass = decryptString(user.password);

    return username === decryptedStoredUser && password === decryptedStoredPass;
  });
};

export const setAuthToken = (username) => {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 7);

  const authData = {
    token: Math.random().toString(36).substr(2) + Date.now().toString(36),
    expiry: expiryDate.getTime(),
    username,
    version: "1.0",
  };

  setCookie(variables.COOKIE_NAME, authData, {
    expires: 7,
    path: "/",
  });
};

export const isAuthenticated = () => {
  const authData = getCookie(variables.COOKIE_NAME);
  if (!authData) return false;

  if (new Date().getTime() > authData.expiry) {
    removeCookie(variables.COOKIE_NAME);
    return false;
  }

  return true;
};

export const logout = () => {
  removeCookie(variables.COOKIE_NAME);
};

export const getUserName = () => {
  const authData = getCookie(variables.COOKIE_NAME);
  return authData.username || "anonymous";
};
