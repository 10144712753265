import React, { useState } from "react";
import JSZip from "jszip";
import { Key } from "./Key";
import {
  first62ImagePropts,
  last6ImageArray,
  tenImagesPropts,
} from "./Super68ImagePropts";
import {
  compressImage,
  sendSlackMessage,
  updateLast6Images,
} from "./utils/utility";
import { getSlackMessagePayload } from "./utils/slack";

const Super68ImageGenerator = () => {
  const [companyName, setCompanyName] = useState("");
  const [stateName, setStateName] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState(null);

  // API key (in a real app, this should be secured)
  const API_KEY = Key;

  const generateRemixImage = async (
    index,
    prompt,
    isRegenerateImage = false
  ) => {
    try {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });
      setError(null);

      // Create FormData instance
      const formData = new FormData();

      // Add the image_request as a string
      const imageRequest = {
        prompt: prompt.prompt,
        aspect_ratio: "ASPECT_16_9",
        image_weight: 80,
        magic_prompt_option: "OFF",
        model: "V_2_TURBO",
      };
      formData.append("image_request", JSON.stringify(imageRequest));

      // Convert the image path to a file and append it
      // Assuming prompt.image contains the path or URL to the image
      const response = await fetch(prompt.image);
      const blob = await response.blob();
      formData.append("image_file", blob, prompt.imageName);

      const apiResponse = await fetch("https://api.ideogram.ai/remix", {
        method: "POST",
        headers: {
          "Api-Key": API_KEY,
          // Note: Don't set Content-Type header when using FormData
          // It will be set automatically with the correct boundary
        },
        body: formData,
      });

      if (!apiResponse.ok) {
        throw new Error(`HTTP error! status: ${apiResponse.status}`);
      }

      const data = await apiResponse.json();

      // Access the URL from the correct path in the response
      if (data.data && data.data[0] && data.data[0].url) {
        setGeneratedImages((prev) => {
          const newImages = [...prev];
          newImages[index] = data.data[0].url;
          return newImages;
        });

        if (isRegenerateImage) {
          // slack
          const info = `*Data:*\nCompany Name: ${companyName}\n State Name: ${stateName}`;
          const slackData = getSlackMessagePayload(isRegenerateImage, info);
          await sendSlackMessage(slackData);
        }
      } else {
        throw new Error("No image URL in response");
      }
    } catch (err) {
      setError(`Error generating remix image: ${err.message}`);
    } finally {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  const generateImage = async (
    prompt,
    index,
    imageName,
    isRegenerateImage = false
  ) => {
    try {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });
      setError(null);

      const response = await fetch("https://api.ideogram.ai/generate", {
        method: "POST",
        headers: {
          "Api-Key": API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_request: {
            prompt,
            aspect_ratio:
              imageName === "56-passenger-motorcoach"
                ? "ASPECT_1_1"
                : "ASPECT_16_9",
            model: "V_2_TURBO",
            magic_prompt_option: "OFF",
            style_type: "GENERAL",
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Access the URL from the correct path in the response
      if (data.data && data.data[0] && data.data[0].url) {
        setGeneratedImages((prev) => {
          const newImages = [...prev];
          newImages[index] = data.data[0].url; // Changed this line to access correct URL path
          return newImages;
        });
        if (isRegenerateImage) {
          // slack
          const info = `*Data:*\nCompany Name: ${companyName}\n State Name: ${stateName}`;
          const slackData = getSlackMessagePayload(isRegenerateImage, info);
          await sendSlackMessage(slackData);
        }
      } else {
        throw new Error("No image URL in response");
      }
    } catch (err) {
      setError(`Error generating image: ${err.message}`);
    } finally {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  const replacePlaceholders = (data, replacements) => {
    return data.map((item) => ({
      ...item,
      prompt: item.prompt
        .replace(/{Company}/g, replacements.company)
        .replace(/{State Name}/g, replacements.stateName),
    }));
  };

  const generateAllImages = async () => {
    if (stateName.trim() === "" || companyName.trim() === "") {
      setError("Please enter company name and state name first");
      return;
    } else {
      setError("");
    }

    const last6ImagePropts = updateLast6Images(
      tenImagesPropts,
      last6ImageArray
    );

    // Get fresh data every time
    const latestImagePropts = [...first62ImagePropts, ...last6ImagePropts];

    const replacedImagesPrompts = replacePlaceholders(latestImagePropts, {
      company: companyName,
      stateName: stateName,
    });

    setPrompts(replacedImagesPrompts);

    // Initialize arrays for new batch
    setGeneratedImages(new Array(replacedImagesPrompts.length).fill(null));
    setLoading(new Array(replacedImagesPrompts.length).fill(false));

    // Generate images in batches of 5
    for (let i = 0; i < replacedImagesPrompts.length; i += 5) {
      const batchPrompts = replacedImagesPrompts.slice(i, i + 5);
      const batchPromises = batchPrompts.map((prompt, batchIndex) => {
        // Check if remix is needed and call appropriate function
        if (prompt?.isRemixNeeded) {
          return generateRemixImage(i + batchIndex, prompt);
        } else {
          return generateImage(prompt.prompt, i + batchIndex, prompt.imageName);
        }
      });
      await Promise.all(batchPromises);
    }
    // slack
    const info = `*Data:*\nCompany Name: ${companyName}\n State Name: ${stateName}`;
    const slackData = getSlackMessagePayload(false, info);
    await sendSlackMessage(slackData);
  };

  const downloadImage = async (url, prompt, imageName) => {
    // Specify Dimension
    let quality = 0.93;
    let imageWidth = 590;
    let imageHeight = 331;

    try {
      // If image aspect ratio is 1:1 update the compression
      if (imageName === "56-passenger-motorcoach") {
        imageWidth = 461;
        imageHeight = 461;
      }

      const blob = await compressImage(url, imageWidth, imageHeight, quality);
      const fileName = `${imageName}.jpeg`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(`Error downloading image: ${err.message}`);
    }
  };

  const downloadAllAsZip = async () => {
    try {
      const zip = new JSZip();

      const downloads = generatedImages.map(async (url, index) => {
        // Specify Dimension
        let quality = 0.93;
        let imageWidth = 590;
        let imageHeight = 331;

        if (!url) return null;

        const fileName = `${prompts[index].imageName}.jpeg`;

        // If image aspect ratio is 1:1 update the compression
        if (fileName === "56-passenger-motorcoach.jpeg") {
          imageWidth = 461;
          imageHeight = 461;
        }

        const blob = await compressImage(url, imageWidth, imageHeight, quality);
        zip.file(fileName, blob);
      });

      await Promise.all(downloads);

      const content = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "generated-images.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(`Error creating zip file: ${err.message}`);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Image Generator</h1>

      {error && <div className="error-message">{error}</div>}

      <div className="input-section">
        <div className="d-flex">
          <input
            type="text"
            placeholder="Enter company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="normal-input mr-2"
          />
          <input
            type="text"
            placeholder="Enter state name"
            value={stateName}
            onChange={(e) => setStateName(e.target.value)}
            className="normal-input ml-2"
          />
        </div>
        <div className="button-group justify-content-center">
          <button
            onClick={generateAllImages}
            disabled={loading.some(Boolean)} //prompts.length === 0 ||
            className="button"
          >
            Generate All Images
          </button>
        </div>
      </div>

      <div className="prompts-container">
        {prompts.map((prompt, index) => (
          <div key={index} className="prompt-card">
            <div className="prompt-text">
              <strong>Prompt {index + 1}:</strong> {prompt.imageName}
            </div>

            {generatedImages[index] && (
              <div className="image-container">
                <img
                  src={generatedImages[index]}
                  alt={prompt.imageName}
                  className="generated-image"
                />
                <div className="button-group">
                  <button
                    onClick={() => {
                      if (prompt?.isRemixNeeded) {
                        generateRemixImage(index, prompt, true); // (index, prompt, isRegenerateImage)
                      } else {
                        generateImage(
                          prompt.prompt,
                          index,
                          prompt.imageName,
                          true
                        ); // (prompt, index, imageName, isRegenerateImage)
                      }
                    }}
                    disabled={loading[index]}
                    className="button"
                  >
                    {loading[index] ? "Generating..." : "Regenerate"}
                  </button>
                  <button
                    onClick={() =>
                      downloadImage(
                        generatedImages[index],
                        prompt.prompt,
                        prompt.imageName
                      )
                    }
                    className="button button-outline"
                  >
                    Download
                  </button>
                </div>
              </div>
            )}

            {loading[index] && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Generating image...</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {generatedImages.some(Boolean) && (
        <div className="actions-container">
          <button onClick={downloadAllAsZip} className="button button-outline">
            Download All
          </button>
        </div>
      )}
    </div>
  );
};

export default Super68ImageGenerator;
